<template>
	<div class="m1">
		<div class="hd ">
			<div class="h1">
				<el-breadcrumb separator="/" style="margin-top: 1.5rem;">
					<el-breadcrumb-item :to="{ path: '/gjsz' }"><span
							style="color: rgba(43, 222, 201, 0.4);">公司列表</span></el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, .4);">{{projectName}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, 1);">{{projectName1}}</span>
					</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="back" @click="back()">
				返回
			</div>
		</div>

		<!-- 列表 -->
		<div class="lb">

			<div class="hd bg">
				<!-- 账号密码 -->
				<div>
					<span class="hd_1">
						序号
					</span>
					<span class="hd_2">
						<div style="margin-left: 1rem;">
							{{projectName1}}
						</div>
					</span>
				</div>
			</div>
			<!-- <div v-if="Data.length == 0" style="color: #FFFFFF; font-size: 4rem;">
        暂无层级！
      </div> -->
			<!-- 主体 -->
			<div style="margin-top: 2rem;">
				<div class="main" v-for="(item,index) in Data" :key="index" @click="To(item)"
					:class="index % 2 == 0?'bg1':'bg2'">
					<div style="width: 100%;display: flex;">
						<div class="m_1">
							{{index + 1}}
						</div>
						<div class="m_2" @click="xian(item)">
							<div style="margin-left: 1rem;">
								{{item.levelName}}
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<!-- 翻页 -->
		<div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
			<!-- <span class="demonstration">完整功能</span> -->
			<el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'gsgly',
		data() {
			return {
				list: [{
						name: '在线检测',
						qr: false
					},
					{
						name: '项目管理',
						qr: false
					}, {
						name: '设备管理',
						qr: false
					}, {
						name: '施工管理',
						qr: false
					}, {
						name: '告警管理',
						qr: false
					}, {
						name: '账号管理',
						qr: false
					}

				],
				value: true,
				currentPage4: 1,
				obj: {
					"page": 1,
					"parentId": 0,
					"projectId": 1,
					"size": 10
				},
				Data: [],
				total: null,
				projectName: '',
				projectName1: ''
			}
		},
		created() {
			let arr1 = decodeURIComponent(this.$route.query.projectName)
			arr1 = JSON.parse(arr1)
			this.projectName = arr1.projectName
			this.projectName1 = arr1.projectName1
			this.obj = {
				"page": 1,
				"parentId": this.$route.query.pid,
				"projectId": this.$route.query.id,
				"size": 10
			}
			this.info()
		},
		methods: {
			back() {
				this.$router.go(-1); //返回上一层
			},
			To(item) {
				let obj = {
					"page": 1,
					"parentId": item.id,
					"projectId": item.projectId,
					"size": 10
				}
				this.$ajax('cjgl', 'post', obj).then(res => {
					console.log(res);
					let arr1 = {
						projectName: this.projectName,
						projectName1: this.projectName1,
						projectName2: item.levelName,
						levelId: item.id,
					}
					let arr = encodeURIComponent(JSON.stringify(arr1))
					if (res.data.records.length > 0) {
						this.$router.push('/gjsz_3?id=' + item.projectId + "&pid=" + item.id + "&projectName=" +
							arr)
					} else {
						this.$router.push('/gjsz_4?id=' + item.projectId + "&pid=" + item.id + "&xs=" + 2 +
							"&projectName=" + arr)
					}
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.obj.size = val
				this.info()

			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.obj.page = val
				this.info()
			},
			xian(item) {
				item.qr = !item.qr
			},
			info() {
				this.$ajax('cjgl', 'post', this.obj).then(res => {
					console.log(res);
					this.Data = res.data.records
					this.total = res.data.total
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.bg {
		background-color: rgba(43, 201, 222, 0.6);
	}

	.bg1 {
		background-color: rgba(162, 162, 162, 0.1);
	}

	.bg2 {
		background: rgba(14, 242, 242, 0.25);
	}

	.m1 {
		position: relative;
		color: #FFFFFF;
		margin: 1rem 0 0 .3rem;
		width: 156.6rem;

		// height: 59.4rem;

		.hd {
			position: relative;
			width: 100%;
			height: 5rem;


			.h1 {
				display: inline-block;
				// width: 11.5rem;
				height: 5rem;
				line-height: 5rem;
				padding-left: 1rem;
				font-size: 1.8rem;
			}

			.h2 {
				position: absolute;
				top: 1rem;
				right: 2rem;
				width: 11.5rem;
				height: 4.2rem;
				background-color: rgba(43, 201, 222, 1);
				line-height: 4.2rem;
				text-align: center;
				border-radius: .5rem;
			}
		}

		.lb {
			position: relative;
			// border: 1px solid #2BDEC9;
			margin-top: 1rem;
			font-size: 1.4rem;
			// overflow: auto;

			.hd {
				width: 100%;
				height: 3.6rem;
				line-height: 3.6rem;
				// padding-left: 1rem;
				font-size: 1.4rem;

				// border-bottom: 1px solid #2BDEC9;
				.hd_1 {
					display: inline-block;
					width: 5%;
					height: 4.2rem;
					line-height: 4.2rem;
					text-align: center;
					margin-right: .5%;
					// background-color: rgba(43, 201, 222, 0.6);
				}

				.hd_2 {
					display: inline-block;
					width: 94.5%;
					height: 4.2rem;
					line-height: 4.2rem;
					// background-color: rgba(43, 201, 222, 0.6);
				}

				.zh {
					margin-left: 1rem;
					display: inline-block;
					width: 16.8rem;
					overflow: hidden;
				}
			}

			.hd:last-child {
				// border-bottom: none;
			}

			.qx {
				position: absolute;
				top: .1rem;
				left: 50rem;
				display: inline-block;

				.lst {
					display: inline-block;
					width: 6.6rem;
					height: 2.6rem;
					line-height: 2.6rem;
					background-color: rgba(43, 201, 222, 0.6);
					text-align: center;
					border-radius: .3rem;
					margin-right: 1rem;
				}
			}

			.xg {
				position: absolute;
				top: .5rem;
				right: 2rem;
				width: 27rem;
				height: 2.6rem;
				line-height: 2.6rem;

				.gg {
					display: inline-block;
					width: 6.6rem;
					height: 2.6rem;
					line-height: 2.6rem;
					text-align: center;
					background-color: rgba(28, 184, 184, 1);
					border-radius: .4rem;
					margin-left: 1rem;
				}
			}
		}

		.main {
			position: relative;
			width: 100%;
			// height: 4.2rem;
			// margin-top: 1rem;
			padding: .5rem 0;

			.m_1 {
				display: inline-block;
				width: 5%;
				height: 4.2rem;
				line-height: 4.2rem;
				text-align: center;
				margin-right: .5%;
				// border: 1px solid rgba(43, 201, 222, 0.6);
				box-sizing: border-box;
			}

			.m_2 {
				display: inline-block;
				width: 94.5%;
				height: 4.2rem;
				line-height: 4.2rem;
				// border: 1px solid rgba(43, 201, 222, 0.6);
				box-sizing: border-box;
			}

			.m_3 {
				position: relative;
				box-sizing: border-box;
				float: right;
				width: 94.5%;
				height: 4.2rem;
				// border: 1px solid rgba(43, 201, 222, 0.6);
				box-sizing: border-box;
				margin-top: .5rem;
				line-height: 4.2rem;

				.zh {
					margin-left: 1rem;
					display: inline-block;
					width: 16.8rem;
					overflow: hidden;
				}

				.qx {
					position: absolute;
					top: .1rem;
					left: 50rem;
					display: inline-block;

					.lst {
						display: inline-block;
						width: 6.6rem;
						height: 2.6rem;
						line-height: 2.6rem;
						background-color: rgba(43, 201, 222, 0.6);
						text-align: center;
						border-radius: .3rem;
						margin-right: 1rem;
					}
				}

				.xg {
					position: absolute;
					top: .5rem;
					right: 2rem;
					width: 27rem;
					height: 2.6rem;
					line-height: 2.6rem;

					.gg {
						display: inline-block;
						width: 6.6rem;
						height: 2.6rem;
						line-height: 2.6rem;
						text-align: center;
						background-color: rgba(28, 184, 184, 1);
						border-radius: .4rem;
						margin-left: 1rem;
					}
				}
			}

			.m_3:last-child {
				margin-bottom: 1rem;
			}
		}

		.main:hover {
			background-color: rgba(117, 117, 117, 0.4);
		}
	}

	.back {
		position: absolute;
		top: 1rem;
		right: 2rem;
		border: 1px solid #1CB8B8;
		border-radius: 0.5rem;
		width: 6.7rem;
		height: 3.8rem;
		line-height: 3.8rem;
		text-align: center;
		color: rgba(28, 184, 184, 1);
		font-size: 1.4rem;
		margin-right: 2rem;
		cursor: pointer;
	}
</style>
