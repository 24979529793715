import { render, staticRenderFns } from "./gjsz_2.vue?vue&type=template&id=4a93ab37&scoped=true&"
import script from "./gjsz_2.vue?vue&type=script&lang=js&"
export * from "./gjsz_2.vue?vue&type=script&lang=js&"
import style0 from "./gjsz_2.vue?vue&type=style&index=0&id=4a93ab37&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a93ab37",
  null
  
)

export default component.exports